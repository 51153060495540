import { Slider, Tooltip } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import '../App.css';

const ResendRatio = (props) => {

  const handleSetValue = (event) => {
    props.setValue(event.target.value);
  }
  
  return (
    <>
      <SliderHeader/>
      <Slider
        style={{ width: '300px' }}
        size="small"
        aria-label="Large"
        valueLabelDisplay="auto"
        value={props.value}
        setValue={props.setValue}
        max={500}
        onChange={handleSetValue}
      />
    </>  
  );
}

const SliderHeader = () => {
    return(
      <header className='App'>
        <span>Resend ratio (%)&nbsp;</span>
        <Tooltip title='Please enter the resend ratio. Default is 5%'>
          <InfoOutlinedIcon
            fontSize="small"
            color="primary"  
          />
        </Tooltip>  
      </header> 
    )
  };

export default ResendRatio;