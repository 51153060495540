

const InfoBox = () => {
    return(
        <>
            <p>This is only an estimation of the battery's lifespan. <br />
            The higher the estimation the larger the chance that the estimation may be faulty. <br />
            This is due to factors that are hard to control and calculate.  <br />
            Read more about Spreading Factors and Battery life <a href="https://sensative.com/sensors/strips-sensors-for-lorawan/sensative-strips-lora-battery-calculator/">here.</a>
            </p>
        </>
    )
}

export default InfoBox;