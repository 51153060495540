import _ from 'lodash';

const stripsProducts = {

  presence: {
    office: {
      label: 'Office',
      value: 'office',
      intervals: {
        PRESENCE_POLL: 120,
      },
      reports_per_h: {
        PRESENCE_REPORT: 7.0/24.0 //Inte det som står i exceldokumentet utan uppdaterade siffror från Lars Mats 11 juli 2022
      }
    },
    public: {
      label: 'Public',
      value: 'public',
      intervals: {
        PRESENCE_POLL: 60,
      },
      reports_per_h: {
        PRESENCE_REPORT: 7.0/24.0
      }
    },
    objectDetection: {
      label: 'Object detection',
      value: 'objectDetection',
      intervals: {
        PROXIMITY_POLL: 600, 
      },
      reports_per_h: {
        PROXIMITY_REPORT: 1.0
      }

    },
    disinfectStatusAlarm: {
      label: 'Disinfect status alarm',
      value: 'disinfectStatusAlarm',
      intervals: {
        PRESENCE_POLL: 120,     
      },
      reports_per_h: {
        PRESENCE_REPORT: 7.0/24.0 //denna har även en delta på 50. Vet inte vilken unit dock...
      }
    }
  },


  comfort: {
    periodic: {       
      label: 'Periodic - temperature, humidity and light',
      value: 'periodic',
      intervals: {      // Använder just nu DELTA REPORTS för att ha något att räkna på
        TEMP_POLL: 120,
        LUX_POLL: 60,
        //HUMIDITY_POLL: 0     //pollen är 0 men koden kraschar när den är med. Det blir rätt svar även utan 0an
      },
      reports_per_h: {
        TEMP_REPORT: 0.5, 
        LUX_REPORT: 1.0, 
        HUMIDITY_REPORT: 0.1
      }
    },
    tempAndHumidity: {
      label: 'Temperature and humidity',
      value: 'tempAndHumidity',
      intervals: {
        TEMP_POLL: 120,
        //HUMIDITY_POLL: 0     //pollen är 0 men koden kraschar när den är med. Det blir rätt svar även utan 0an
      },
      reports_per_h: {
        TEMP_REPORT: 0.5, 
        HUMIDITY_REPORT: 0.1
      }
    },
    tempHumLight: {
      label: 'Temperature humidity and light',
      value: 'tempHumLight',
      intervals: {
        TEMP_POLL: 120,
        LUX_POLL: 60,      
        //HUMIDITY_POLL: 0     //pollen är 0 men koden kraschar när den är med. Det blir rätt svar även utan 0an
      },
      TEMP_REPORT: 0.5,
      LUX_REPORT: 1.0,
      HUMIDITY_REPORT: 0.1
    },
  },


  drip: {
    waterLeakAlarm: {
      label: 'Water leak alarm',
      value: 'waterLeakAlarm',
      intervals: {
        FLOOD_POLL: 30,
      } ,
      reports_per_h:{
        WATER_LEAK_REPORT: 0.001
      }
    },
    waterLeakAndFrostAlarm: {
      label: 'Water leak and frost alarm',
      value: 'waterLeakAndFrostAlarm',
      intervals : {
        FLOOD_POLL: 30,    
        TEMP_POLL: 120,
      },
      reports_per_h:{
        WATER_LEAK_REPORT: 0.001,
        TEMP_REPORT:0.5
      }
    },
    averageTemp: {
      label: 'Average temperature',
      value: 'averageTemp',
      intervals: {
        TEMP_POLL: 120,
      },
      reports_per_h:{
        TEMP_REPORT: 0.5 //Stämmer inte med config
      }
    },
    coldStorage: {
      label: 'Cold storage',
      value: 'coldStorage',
      intervals: {
        TEMP_POLL: 120,
      },
      reports_per_h:{
        TEMP_REPORT: 0.5 
      }
    }
  },


  guard: {
    doorWindowAlarm: {
      label: 'Door/window alarm',
      value: 'doorWindowAlarm',
      intervals: {
        DOOR_POLL: 1.5,
      },
      reports_per_h:{
        DOOR_REPORT:0.1 
      }
      
    },
    doorCounter: {
      label: 'Door counter',
      value: 'doorCounter',
      intervals: {
        DOOR_POLL: 3600,
      },
      reports_per_h:{
        DOOR_REPORT: 0.1,
      }
    }
  },
}

const baseIntervals = {
  BASE_POLL: 1.5,
}

const pollConsumptions_uA = {
  FLOOD_POLL: 88.1,
  TEMP_POLL: 632, //Internal temp sensor
  BASE_POLL: 68.3,
  PRESENCE_POLL: 710.0,
  PROXIMITY_POLL: 820.0,
  LUX_POLL: 505.0,
  HUMIDITY_POLL: 438.0,
  DOOR_POLL: 0.00000001     //Dess riktiga värde är 0, men då smäller koden. Uträknignen med denna siffra ger nästan rätt svar och avrundas ändå
}

const pollDurations_ms = {
  FLOOD_POLL: 16.8,
  TEMP_POLL: 21.9,
  BASE_POLL: 12.1,
  PRESENCE_POLL: 40.3,
  PROXIMITY_POLL: 40.2,
  LUX_POLL: 140.6,
  HUMIDITY_POLL: 113.8,
  DOOR_POLL: 0.00000001   //Dess riktiga värde är 0, men då smäller koden. Uträknignen med denna siffra ger nästan rätt svar och avrundas ändå
}

const regionsOptions = { // siffrorna på default är helt påhittade för att kunna köra tester
  europe: {
    label: 'Europe',
    value: 'europe',
    default: 25
  },
  asia: {
    label: 'Asia',
    value: 'asia',
    default: 25
  },
  africa: {
    label: 'Africa',
    value: 'africa',
    default: 50
  },
  southAmerica: {
    label: 'South America',
    value: 'southAmerica',
    default: 23879032
  },
  northAmerica: {
    label: 'North America',
    value: 'northAmerica',
    default: 72859904
  },
  oceania: {
    label: 'Oceania',
    value: 'oceania',
    default: 738572975
  }
};

const spreadingFactorOptions = {
  seven: {
    label: "7",
    consumption_uA: 1.02,
    duration_ms: 2312.6
  }, 
  eight: {
    label: "8",
    consumption_uA: 1.21,
    duration_ms: 2352.7
  },
  nine: {
    label: "9",
    consumption_uA: 1.5,
    duration_ms: 2426.4
  },
  ten: {
    label: "10",
    consumption_uA: 2.14,
    duration_ms: 2589.9
  },
  eleven: {
    label: "11",
    consumption_uA: 3.19,
    duration_ms: 2923.1
  },
  twelve: {
    label: "12",
    consumption_uA: 4.71,
    duration_ms: 3579.7
  }
}

const baseReports_per_h = {
  BASE_REPORT: 1.0/24.0,
}

export {
  regionsOptions, 
  stripsProducts, 
  spreadingFactorOptions, 
  baseIntervals, 
  pollConsumptions_uA, 
  pollDurations_ms, 
  baseReports_per_h,
};